import React from "react";
import "../../styles/style.css";
import "../../styles/main.css";
import NavbarEn from "../../components/navbar/english";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import FooterEn from "../../components/footer/english";
import SideSocialMediaBar from "../../components/social_media_bar/english";
import migrant from "../../assets/images/migrant_loans.png";
import premier from "../../assets/images/premier_loans.png";
import responsive from "../../assets/icons/responsive.png";
import franchise from "../../assets/icons/franchise.png";
import coupons from "../../assets/icons/coupons.png";
import coinstack from "../../assets/icons/coin-stack.png";

const ConsumerLoanEn = () => {
  return (
    <div>
      <NavbarEn langToggleHref="/consumer-loans"/>
      <SideSocialMediaBar />
      <Container className="consumer_loans">
        <Row>
          <Col>
            <h1>
              Consumer Loans
            </h1>
          </Col>
        </Row>
        <Row className="my-2">
          <Col md={6} sm={12}>
            <div>
              <img src={migrant} />
            </div>
          </Col>
          <Col md={6} sm={12} style={{ textAlign: "left" }}>
            <div>
              <h3 className="mt-lg-4">Migrant Loan</h3>
              <p>
              $500 to $2,500 
<br/>
3 months – 6 months
<br/>
Biweekly payments (Every 2 weeks)
<br/>
Only have a passport? You're eligible for a $500 beginner loan.
                <br />
              </p>
            </div>
          </Col>
        </Row>
        {/* <Row className="my-2 px-lg-5 px-3">
        <h4
                style={{
                  color: "rgb(232, 0, 120)",
                  fontFamily: "RockoUltraFLF",
                }}
              >                Fees
              </h4>

            <table style={{textAlign:'left'}}>
              <tr>
                <td>Administration Fee – Account Handling Customary Fee</td>
                <td>1.05% of the remaining capital per Installment</td>
              </tr>
              <tr>
                <td>Administration Fee - Maintenance Fee </td>
                <td>$1.25 per Installment</td>
              </tr>
              <tr>
                <td>Late fee (15 days)</td>
                <td>$15.00</td>
              </tr>
              <tr>
                <td>NSF (Non-Sufficient Funds Fee)</td>
                <td>$15.00</td>
              </tr>
              <tr>
                <td>Origination Fee – Closing Fee</td>
                <td>$25.00</td>
              </tr>
              <tr>
                <td>Origination fee - Processing fee </td>
                <td>10.00% of the loan amount</td>
              </tr>
              <tr>
                <td>Past Due Interest</td>
                <td>12.50% per year of past due principal</td>
              </tr>
            </table>
        </Row> */}

        <Row className="my-5">
          <Col md={6} sm={12}>
            <div>
              <img src={premier} />
            </div>
          </Col>
          <Col md={6} sm={12} style={{ textAlign: "left" }}>
            <div>
              <h3 className="mt-lg-4">Premier Loan</h3>
              <p>
              $2,500 to $5,000
              <br/>
Customizable Term 3 months – 6 months
<br/>
Biweekly payments (Every 2 weeks)
<br/>
Available exclusively for current clients who've maintained a record of timely payments with Nuestro.
                <br />
              </p>
            </div>
          </Col>
        </Row>
        {/* <Row className="my-2 px-lg-5 px-3">
        <h4
                style={{
                  color: "rgb(232, 0, 120)",
                  fontFamily: "RockoUltraFLF",
                }}
              >
                Fees
              </h4>
            <table style={{textAlign:'left'}}>
              <tr>
                <td>Administration Fee – Account Handling Customary Fee</td>
                <td>1.25% of the remaining capital per Installment</td>
              </tr>
              <tr>
                <td>Administration Fee - Maintenance Fee </td>
                <td>$2.50 per Installment</td>
              </tr>
              <tr>
                <td>Late fee (15 days)</td>
                <td>$15.00</td>
              </tr>
              <tr>
                <td>NSF (Non-Sufficient Funds Fee)</td>
                <td>$15.00</td>
              </tr>
              <tr>
                <td>Origination Fee – Closing Fee</td>
                <td>$25.00</td>
              </tr>
              <tr>
                <td>Origination fee - Processing fee </td>
                <td>10.00% of the loan amount</td>
              </tr>
              <tr>
                <td>Past Due Interest</td>
                <td>12.50% per year of past due principal</td>
              </tr>
            </table>
        </Row> */}
      </Container>
      <FooterEn />
    </div>
  );
};

export default ConsumerLoanEn;
