import React, {useEffect, useState, useRef} from "react";
import "../../styles/style.css";
import "../../styles/main.css";
import Navbar from "../../components/navbar/spanish";
import happyboy from "../../assets/images/landing_page_banner.png";
import products from "../../assets/images/productos.png";
import m1 from "../../assets/images/masonary/m12.jpg";
import whynuestro1 from "../../assets/images/why_nuestro_es_1.0.png";
import whynuestro2 from "../../assets/images/why_nuestro_es_1.4.png";
import whynuestro3 from "../../assets/images/why_nuestro_es_1.5.png";
import whynuestro4 from "../../assets/images/why_nuestro_es_1.3.webp";
import banner_bg from "../../assets/images/banner_bg_copy.png";
import mobile_banner_bg from "../../assets/images/banner_bg.png";
import banner from "../../assets/images/banner_es.png";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import FooterEs from "../../components/footer/spanish";
import click from "../../assets/gifs/click.webm";
import SideSocialMediaBar from "../../components/social_media_bar/english";
import logo_ee from "../../assets/images/logo_electronic_express.png";

const HomeEs = () => {

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isMacOS = /Macintosh/.test(navigator.userAgent);

  const shouldRenderVideo = !isIOS && !isMacOS;

  const refPopup = useRef(null);

  useEffect(() => {
    //refPopup.current.style.display = "block";
  });

  return (
    <div>
      <Navbar langToggleHref="/en"/>
      <SideSocialMediaBar />

      <div className="popup" ref={refPopup}>
        <p>
        Debido a un aumento en las solicitudes de préstamos, estamos suspendiendo temporalmente las nuevas solicitudes para asegurar que mantenemos nuestro compromiso de brindar un servicio excepcional. Tenga la seguridad de que nuestro equipo está dedicado a procesar las solicitudes existentes de manera eficiente.
        <br/>
        <br/>
        Para nuestros clientes actuales, no dude en llamarnos si necesita ayuda. Estamos aquí para ayudarle. Gracias por su paciencia y comprensión durante este tiempo.
        </p>
        <a href="#">Let's Go</a>
    </div>

      <div style={{ backgroundColor: "rgb(232, 0, 120)" }}>
        <Container fluid>
          <Row>
            <Col sm={6} md={6} xs={6} style={{ zIndex: 50 }}>
              <div style={{ textAlign: "right" }}>
                <img
                  src={happyboy}
                  className="slide-in-left banner-img"
                  style={{ zIndex: 50 }}
                />
                <div className="below_banner_text">
                  <p>
                  Debido a un aumento en las solicitudes de préstamos, estamos suspendiendo temporalmente las nuevas solicitudes para asegurar que mantenemos nuestro compromiso de brindar un servicio excepcional. Tenga la seguridad de que nuestro equipo está dedicado a procesar las solicitudes existentes de manera eficiente.
                  <br/>
                  Para nuestros clientes actuales, no dude en llamarnos si necesita ayuda. Estamos aquí para ayudarle. Gracias por su paciencia y comprensión durante este tiempo.
                  </p>
                </div>
              </div>
            </Col>
            <Col sm={6} md={6} xs={6}>
              <div className="slide-in-right home_banner_heading">
                <div style={{ textAlign: "left" }}>
                  <img src={banner} className="banner_text_img" />
                  <div style={{ textAlign: "right" }}>
                  <a
                      href="https://nuestro-loans.turnkey-lender.com/Account/Login"
                      className="py-lg-3 py-1 banner-apply-button grow-effect"
                    >
                     {shouldRenderVideo && (
  <video className="click_here" loop={true} autoPlay={true} muted playsInline>
    <source src={click} type="video/webm" />
  </video>
)} &nbsp; Ingresa
                    </a>
                  </div>
                  <div></div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <img src={banner_bg} width="100%" className="below_banner_img" />
        <img
          src={mobile_banner_bg}
          width="100%"
          className="mobile_below_banner_img"
        />
      </div>
      <Container fluid className="loan_video">
      <Row>
        <Col md="4" sm="12">
        <h1>Cómo solicitar un préstamo</h1>
        </Col>
        <Col md="2" sm="0">
        {shouldRenderVideo && (
              <video loop={true} autoPlay={true} muted playsInline style={{paddingTop:'80px'}}>
                <source src={click} type="video/webm" />
              </video>
            )}
        </Col>
        <Col md="5" sm="12">
        <div className="faq_video" >
        <iframe src="https://www.youtube.com/embed/nlI8YhxgQI0?si=S01vOV8qfqkYAEwB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        </Col>
      </Row>
      </Container>
      <div style={{ textAlign: "center", padding: "40px 0" }}>
        <img src={products} width="47%" className="slide-in-left" />
      </div>
      <Container className="pb-lg-5">
        <Row className="d-flex align-items-stretch">
          <Col  md={6} sm={12}>
            {/* Content for the second column */}
            <div
              className="text-center d-flex flex-column justify-content-between h-100"
              style={{ padding: "15px", marginBottom: "15px" }}
            >
              <div className="px-4 pt-lg-5 pt-3 slide-in-left productos">
                <h4 style={{ fontFamily: "AvenirBold" }}>
                Prestamo Migrante
                </h4>
                <br />
                <p>
                Para ti que llegaste a USA con todas las ganas de hacer las cosas bien! Nuestro te acompaña a construir un futuro lleno de éxito. Con o sin ITIN, no necesitas historial de crédito previo para comenzar a construir tu futuro financiero. Oportunidades para clientes nuevos comenzando desde $500! Aplica fácil para saber con cuanto puedes comenzar.
                  <br />
                  <br />
                  <span style={{ fontFamily: "AvenirBold" }}>
                  *Limite de $2,500. Toda aprobación está sujeta a nuestro proceso de crédito.
                  </span>
                </p>
              </div>
            </div>
          </Col>
          <Col md={6} sm={12}>
            {/* Content for the first column */}
            <div
              className="text-center d-flex flex-column justify-content-between h-100"
              style={{ padding: "15px", marginBottom: "15px" }}
            >
              <div className="px-4 pt-lg-5 pt-3 slide-in-left productos">
                <h4 style={{ fontFamily: "AvenirBold" }}>
                Prestamo Premier
                </h4>
                <br />
                <p>
                Solo para nuestros clientes Premier. Si ya eres cliente de nosotros, necesitas más de $2,500 y tienes impecable historial de pagos, este producto es solo para ti! Aquí premiamos tu compromiso con tu éxito financiero
                <br />
                <br />
                  <span style={{ fontFamily: "AvenirBold" }}>
                  *Limite de $5,000. Únicamente para clientes de Nuestro con historial de pago positivo.
                  </span>
                </p>
              </div>
            </div>
          </Col>
          <Col md={6} sm={12}>
            {/* Content for the first column */}
            <div
              className="text-center d-flex flex-column justify-content-between h-100"
              style={{ padding: "15px", marginBottom: "15px" }}
            >
              <div className="px-4 pt-lg-5 pt-3 slide-in-left productos">
                <h4 style={{ fontFamily: "AvenirBold" }}>
                Prestamo para Negocio
                </h4>
                <br />
                <p>
Para nuestros emprendedores y dueñas de negocio este producto es para ustedes! Aquí apoyamos el crecimiento de tu compañía  y nos comprometemos con tu éxito financiero. Si tu negocio esta registrado como una LLC (Limited Liability Company) puedes calificar por hasta $25,000.
                <br />
                <span style={{color:'rgb(33, 67, 136)', fontWeight:'bold'}}>Código Promocional: NEGOCIO</span>
                <br />
                  <span style={{ fontFamily: "AvenirBold" }}>
                  *Este crédito está disponible para dueños de negocio con ITIN y una LLC registrada en Tennessee.
                  </span>
                </p>
              </div>
            </div>
          </Col>
          <Col  md={6} sm={12}>
            {/* Content for the first column */}
            <div
              className="text-center d-flex flex-column justify-content-between h-100"
              style={{ padding: "15px", marginBottom: "15px" }}
            >
              <div className="px-4 pt-lg-3 pt-3 slide-in-left productos">
              <img src={logo_ee} width="150px"/>
                <h4 style={{ fontFamily: "AvenirBold" }}>
                Prestamo Express
                </h4>
                <p>
                Para ti que quieres mejorar tu hogar, cambiar electrodomésticos o quieres aprovechar ofertas de Electronic Express a crédito.  Nuestro y Electronic Express lo hacen realidad. Con o sin ITIN, no necesitas historial de crédito previo y te sirve para comenzar a construir tu futuro financiero. Oportunidades para clientes nuevos comenzando desde $500! Aplica fácil y recibe respuesta en 24 horas.
                  <br />
                  <span style={{color:'rgb(33, 67, 136)', fontWeight:'bold'}}>Código Promocional: EXPRESS</span>
                  <br />
                  <span style={{ fontFamily: "AvenirBold" }}>
                  *Limite de $1,000. Toda aprobación está sujeta a nuestro proceso de crédito.
                  </span>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="fl_home">
        <Row>
          <h1>Talleres Gratuitos de Educación Financiera</h1>
          <p>
Bienvenidos a los Talleres Gratuitos de Educación Financiera de Nuestro, empoderando a las comunidades con conocimientos financieros esenciales todos los lunes, de 7:00 p.m. a 8:00 p.m. en 444 Metroplex Dr. Suite 225-B.  </p>
          <div style={{textAlign:'center', marginBottom:'10px'}}>
          <img src={m1} style={{width:'320px', borderRadius:'30px'}}/>
          </div>
          <h4>¡Toma el control de tu futuro financiero con nosotros!</h4>
          <div style={{textAlign:'center', padding:'30px 40px'}}>
          <a href="/es/financial-literacy">Conocer más</a>
          </div>
        </Row>
      </Container>
      <div style={{ backgroundColor: "rgb(232, 0, 120)" }}>
        <Container>
          <Row
            className="p-4  pe-5 slide-in-right"
            style={{ textAlign: "right" }}
          >
            <h1
              style={{
                fontFamily: "RockoUltraflf",
                fontSize: "4em",
                color: "#ffffff",
              }}
            >
              ¿Por qué
              <span
                style={{
                  color: "rgb(33, 67, 136)",
                  fontFamily: "RockoUltraflf",
                }}
              >
                {" "}
                Nuestro?
              </span>{" "}
            </h1>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <Col xs={12} md={6} sm={12}>
              <Row>
                <Col xs={6} md={6} sm={6}>
                  <img
                    src={whynuestro1}
                    width="90%"
                    className="pt-1 pb-1 circular-grow"
                  />
                </Col>
                <Col xs={6} md={6} sm={6}>
                  <img
                    src={whynuestro2}
                    width="90%"
                    className="pt-1 pb-1 circular-grow"
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6} sm={12}>
              <Row>
                <Col xs={6} md={6} sm={6}>
                  <img
                    src={whynuestro3}
                    width="90%"
                    className="pt-1 pb-1 circular-grow"
                  />
                </Col>
                <Col xs={6} md={6} sm={6}>
                  <img
                    src={whynuestro4}
                    width="90%"
                    className="pt-1 pb-1 circular-grow"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="p-5 slide-in-left">
            <h1
              style={{
                fontFamily: "RockoUltraflf",
                fontSize: "4em",
                color: "#ffffff",
              }}
            >
              ¿Cómo funciona?
            </h1>
          </Row>
          <Row className="pe-5 ps-5">
            <Col
              className="p-5 slide-in-right"
              style={{
                backgroundColor: "#ffffff",
                color: "rgb(33, 67, 136)",
                borderRadius: "30px",
              }}
            >
              <p>
              1. Haga clic en el botón "Aplique Ahora" para empezar.
              </p>
              <p>
              2. Elige el producto de crédito adecuado, el importe y el plazo de amortización que buscas. Nuestros productos tienen diferentes usos, así que asegúrate de elegir el adecuado.
              </p>
              <ul>
                <li>Prestamo Migrante: Oportunidades para clientes nuevos comenzando desde $500! Aplica fácil para saber con cuanto puedes comenzar</li>
                <li>Prestamo Premier: Solo para nuestros clientes existentes Premier.</li>
                <li>Prestamo Express: Para necesidades de tu hogar, en colaboración con Electronic Express.</li>
                <li>Prestamo Negocio: Especialmente diseñado para negocios registrados como LLC en Tennessee. Hasta $25,000 para capitalizar tu negocio.</li>
              </ul>
              <p>
              3. Para todos los préstamos, vas a necesitar los siguientes documentos:
              </p>
              <ul>
                <li>Foto ID</li>
                <li>Comprobante de domicilio</li>
                <li>Comprobante de empleo / sueldo</li>
                <li>Tres últimos estados de cuenta bancarios</li>
                <li>Cuenta bancaria válida</li>
              </ul>
              <p>
              4. Una vez aprobado y firmado, recibirá el dinero a través de depósito directo en su cuenta bancaria conectada en un plazo aproximado de 48 horas.
              </p>
              <p>
              5. Mejora tu puntuación de crédito. Al cumplir con tus pagos mensuales, vas a conseguir un mejor puntaje de crédito. Nuestro Loans reporta a Clarity, que es parte de Experian, un buró de crédito, ayudándote a mejorar tu perfil crediticio. En nuestros préstamos NO HAY comisiones por pago anticipado, por lo que puedes cancelarlo antes de tiempo sin cargos extra.
              </p>
              <p style={{ fontSize: "10px" }}>
                * Debes de tener la documentación necesaria para aplicar a estos
                préstamos.
                <br />
                **Si no tienes una cuenta bancaria consulta{" "}
                <a style={{ color: "black" }} href="https://nuestrowallet.com">
                  www.nuestrowallet.com
                </a>
                , es posible que podamos ayudarte.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <FooterEs />
    </div>
  );
};

export default HomeEs;
