import React from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import FooterEn from "../../components/footer/spanish";
import NavbarEn from "../../components/navbar/spanish";
import { Container } from "react-bootstrap";
import "../../styles/style.css"
import SideSocialMediaBar from "../../components/social_media_bar/english";

const FaqEn = () => {
  return (
    <div style={{ overflow: "hidden" }}>
      <NavbarEn langToggleHref="/en/faqs"/>
      <SideSocialMediaBar />
      <Container fluid className="faq_main">
        <div className="faq_video">
        <iframe src="https://www.youtube.com/embed/nlI8YhxgQI0?si=S01vOV8qfqkYAEwB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div class="row">
          <div class="col-4">
            <div
              id="simple-list-example"
              class="d-flex flex-column gap-2 simple-list-example-scrollspy py-4 ps-lg-5 ps-2 link_box"
            >
              <h2 style={{color:'rgb(232, 0, 120)', fontFamily:'RockoUltraFLF'}}>FAQs</h2>
              <a class="p-1 rounded faq_link" href="#simple-list-item-1">
                Productos de préstamo 
              </a>
              <a class="p-1 rounded faq_link" href="#simple-list-item-2">
                Proceso de solicitud 
              </a>
              <a class="p-1 rounded faq_link" href="#simple-list-item-3">
              Aprobación del préstamo y tiempo de procesamiento
              </a>
              <a class="p-1 rounded faq_link" href="#simple-list-item-4">
                Tasas de interés y términos 
              </a>
              <a class="p-1 rounded faq_link" href="#simple-list-item-5">
                Seguridad y privacidad 
              </a>
              <a class="p-1 rounded faq_link" href="#simple-list-item-6">
                Información de pago adicional 
              </a>
              <a class="p-1 rounded faq_link" href="#simple-list-item-7">
                Información del contacto 
              </a>
            </div>
          </div>
          <div class="col-8 p-lg-5 p-3">
            <div
              data-bs-spy="scroll"
              data-bs-target="#simple-list-example"
              data-bs-offset="0"
              data-bs-smooth-scroll="true"
              class="scrollspy-example faq_scroll"
              tabindex="0"
            >
              <h4 className="faq_heading" id="simple-list-item-1">Productos de préstamo </h4>
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOneOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      1.1 ¿Qué tipos de préstamos ofrece Nuestro? 
                    </button>
                  </h2>
                  <div
                    id="collapseOneOne"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                    <b>Préstamo Migrante</b> : Este es para clientes nuevos o clientes Sin ITIN 
<br/>
                    <b>Préstamo Premier</b>: Para nuestra clientela existente 
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOneTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                     1.2 ¿Qué es Nuestro Préstamo Migrante? 
                    </button>
                  </h2>
                  <div
                    id="collapseOneTwo"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                    ¡Nuestro Préstamo Migrante! ¡Este Producto fue cuidadosamente diseñado para nuestra comunidad que califica con SSN, ITIN o solo con pasaporte! Ya sea que lo necesite para expandir su negocio, para una reparación de automóvil, facturas, gastos legales, gastos funerarios, viaje o cualquier otra necesidad, Nuestro está aquí para ayudarlo a comenzar a emprender.. 
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOneThree"
                      aria-expanded="true"
                      aria-controls="collapseThree"
                    >
                      1.3 ¿Qué es Nuestro Premier? 
                    </button>
                  </h2>
                  <div
                    id="collapseOneThree"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                    Nuestro Préstamo Premier es para nuestra clientela existente que, después de pagar el primer préstamo, necesita o quiere otro. 
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOneFour"
                      aria-expanded="true"
                      aria-controls="collapseThree"
                    >
                      1.4 ¿Puedo obtener un segundo préstamo con Nuestro? 
                    </button>
                  </h2>
                  <div
                    id="collapseOneFour"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                    Nuestro solo permite un préstamo activo por cliente. 
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOneFive"
                      aria-expanded="true"
                      aria-controls="collapseThree"
                    >
                      1.5 Vivo fuera del estado, ¿aun así puedo presentar la solicitud? 
                    </button>
                  </h2>
                  <div
                    id="collapseOneFive"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                    Nuestro solo permite a los residentes de Tennessee solicitar nuestros productos de préstamo. 
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOneSix"
                      aria-expanded="true"
                      aria-controls="collapseThree"
                    >
                      1.6 No me aprobaron el monto que solicité, ¿por qué? 
                    </button>
                  </h2>
                  <div
                    id="collapseOneSix"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                    Si Nuestro no puede ofrecer la cantidad solicitada, haremos un seguimiento con una contraoferta.
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="faq_heading" id="simple-list-item-2">Proceso de solicitud </h4>
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwoOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      2.1 ¿Cómo puedo solicitar un préstamo con Nuestro? 
                    </button>
                  </h2>
                  <div
                    id="collapseTwoOne"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                    Solicitar un préstamo es sencillo y cómodo. Puede completar el proceso de solicitud en línea a través de nuestro sitio web. Simplemente complete la información requerida y envíe su solicitud. 
                      <br />
                      <br />
                      Enlace -{" "}
                      <a
                        href="https://nuestro-loans.turnkey-lender.com/Lending#/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://nuestro-loans.turnkey-lender.com/Lending#/terms
                      </a>
                      <br />
                      <br />
                      ¡Si necesita ayuda, pase por nuestras oficinas! 
                      <br />
                      <br />
Nashville - 444 Metroplex Dr Unidad B-225, Nashville, TN 37211 
<br />
                      <br />
Shelbyville - 405 Thompson St, Shelbyville, TN 37160 
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwoTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      2.2 ¿Cuánto tiempo lleva completar la solicitud? 
                    </button>
                  </h2>
                  <div
                    id="collapseTwoTwo"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                    ¡30 minutos a 1 hora! ¡Pase por nuestras oficinas durante su hora de almuerzo! 
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="true"
                      aria-controls="collapseThree"
                    >
                      2.3 ¿Qué documentos se requieren para la solicitud de préstamo? 
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                    Los documentos requeridos pueden variar según el tipo de préstamo que esté solicitando. 
                    <br />
                      <br />
Los documentos comúnmente solicitados incluyen: 
                      <ul>
<li>identificación fotográfica del Gobierno vigente (no expirada) </li>
<li>Prueba de domicilio </li>
<li>Prueba de empleo/pago </li>
<li>Tres extractos bancarios más recientes </li>
<li>Cuenta bancaria válida </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="faq_heading" id="simple-list-item-3">Aprobación del préstamo y tiempo de procesamiento </h4>
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThreeOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      3.1 ¿Cuánto tiempo lleva aprobar un préstamo? 
                    </button>
                  </h2>
                  <div
                    id="collapseThreeOne"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                    Una vez que tengamos TODA la documentación e información, dénos entre 24 y 48 horas para revisar la solicitud. 
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThreeTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      3.2 ¿Qué pasa si mi solicitud es rechazada? 
                    </button>
                  </h2>
                  <div
                    id="collapseThreeTwo"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                    Recibirás un SMS y una notificación por correo electrónico para avisarte de la decisión de préstamo una vez tomada. 
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThreeThree"
                      aria-expanded="true"
                      aria-controls="collapseThree"
                    >
                     3.3 Una vez aprobado ¿cuánto tiempo se tarda en recibir los fondos? 
                    </button>
                  </h2>
                  <div
                    id="collapseThreeThree"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                    Una vez aprobado y firmado, recibirá el dinero mediante depósito directo en su cuenta bancaria conectada en aproximadamente 48 horas. 
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="faq_heading" id="simple-list-item-4">Tasas de interés y términos </h4>
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFourOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      4.1 ¿Cuáles son las tasas de interés de los préstamos Nuestro? 
                    </button>
                  </h2>
                  <div
                    id="collapseFourOne"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                    Las tasas de interés y los términos varían según el tipo de préstamo y otros factores. Ofrecemos tarifas competitivas y términos flexibles para satisfacer sus necesidades. Se proporcionarán detalles específicos durante el proceso de solicitud. 
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFourTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      4.2 ¿Cuáles son los plazos de pago disponibles? 
                    </button>
                  </h2>
                  <div
                    id="collapseFourTwo"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                    Se pueden elegir términos personalizados de 3, 4, 5 y 6 meses para la duración del pago. 
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="faq_heading" id="simple-list-item-5">Seguridad y privacidad </h4>
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFiveOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                     5.1 ¿Cómo se protege mi información personal durante el proceso de solicitud de préstamo? 
                    </button>
                  </h2>
                  <div
                    id="collapseFiveOne"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                    Nos tomamos en serio la seguridad y privacidad de su información. Nos adherimos a estrictas políticas de privacidad para garantizar que su información permanezca confidencial. 
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="faq_heading" id="simple-list-item-6">Información de pago adicional </h4>
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSixOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      6.1 ¿Con qué frecuencia puedo realizar pagos y cuáles son los métodos de pago disponibles? 
                    </button>
                  </h2>
                  <div
                    id="collapseSixOne"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                    Los pagos se requieren cada 15 días, se aceptan pagos adicionales. 
<br/>
<b>Autocharge</b> carga automáticamente su cuenta bancaria conectada; sin embargo, no dude en llamarnos para solicitar los diferentes métodos de pago disponibles. 
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSixTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      6.2 ¿Puedo re-programar los pagos de mi préstamo?  
                    </button>
                  </h2>
                  <div
                    id="collapseSixTwo"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                    Llámenos al {" "}
                      <a
                        href="tel:615-800-6181"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        615-800-6181
                      </a>{" "}
                      para obtener más información sobre la reprogramación. 
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSixThree"
                      aria-expanded="true"
                      aria-controls="collapseThree"
                    >
                      6.3 ¿Puedo realizar pagos adicionales o liquidar mi préstamo anticipadamente? 
                    </button>
                  </h2>
                  <div
                    id="collapseSixThree"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                    ¡Sí! Para realizar un pago adicional, inicie sesión en su cuenta de préstamo y realice un pago. Alternativamente,  
<br/><br/>
¡Si necesitas ayuda, pásate por nuestras oficinas! 
<br/><br/>
Nashville - 444 Metroplex Dr Unidad B-225, Nashville, TN 37211 
<br/><br/>
Shelbyville - 405 Thompson St, Shelbyville, TN 37160 
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSixFour"
                      aria-expanded="true"
                      aria-controls="collapseThree"
                    >
                      6.4 ¿Hay algún cargo por cancelar mi préstamo anticipadamente? 
                    </button>
                  </h2>
                  <div
                    id="collapseSixFour"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                    Es una buena práctica verificar su contrato de préstamo para detectar multas por pago anticipado; sin embargo, los productos Migrante y Préstamo Premier NO tienen 
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="faq_heading" id="simple-list-item-7">Información del contacto </h4>
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSevenOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      7.1 ¿Cómo puedo comunicarme con Nuestro para obtener más ayuda? 
                    </button>
                  </h2>
                  <div
                    id="collapseSevenOne"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                    Si tiene alguna pregunta o necesita ayuda, no dude en ponerse en contacto con nuestro equipo de atención al cliente. Puede comunicarse con nosotros por teléfono, correo electrónico o puede visitarnos. 
                      <br />
                      <br />
                      Enlace  -{" "}
                      <a
                        href="https://www.nuestro.loans/contactus"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://www.nuestro.loans/contactus
                      </a>
                      <br/><br/>
                      Si necesita ayuda, pase por nuestras oficinas! 
                      <br/><br/>
Nashville - 444 Metroplex Dr Unidad B-225, Nashville, TN 37211 
<br/><br/>
Shelbyville - 405 Thompson St, Shelbyville, TN 37160 
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed faq_question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSevenTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      7.2 ¿Cómo puedo programar una visita a la Oficina Nuestro? 
                    </button>
                  </h2>
                  <div
                    id="collapseSevenTwo"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body faq_text">
                    ¡Nuestras puertas están abiertas! 
                    <br/><br/>
Nashville: 
<br/><br/>
Lunes Viernes 
<br/><br/>
9:00 am – 5:00 pm 
<br/><br/>
Sábado 
<br/><br/>
9:00am – 1:00pm 
<br/><br/>
Shelbyville: 
<br/><br/>
Lunes Viernes 
<br/><br/>
4:00pm -7:00pm 
<br/><br/>
Sábado 
<br/><br/>
9:00am – 1:00pm 
                    </div>
                  </div>
                </div>
              </div>
              <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            </div>
          </div>
        </div>
      </Container>
      <FooterEn />
    </div>
  );
};

export default FaqEn;
