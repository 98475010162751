import React from "react";
import "../../styles/style.css";
import "../../styles/main.css";
import NavbarEn from "../../components/navbar/spanish";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import FooterEn from "../../components/footer/spanish";
import SideSocialMediaBar from "../../components/social_media_bar/english";
import migrant from "../../assets/icons/migrant.png"
import premier from "../../assets/icons/premier.png"
import responsive from "../../assets/icons/responsive.png"
import franchise from "../../assets/icons/franchise.png"
import coupons from "../../assets/icons/coupons.png"
import coinstack from "../../assets/icons/coin-stack.png"

const ProductEs = () => {

  return (
    <div>
      <NavbarEn langToggleHref="/en/products"/>
      <SideSocialMediaBar />
      <Container className="Product_heading">
        <Row>
            <Col>
            <h1>Productos de Préstamo</h1>
            </Col>
        </Row>
        <Row>
            <Col md={6} sm={12}>
                <div className="product_card_main">
                    <img src={migrant}/>
                    <h3>Préstamo Migrante</h3>
                    <p>
De $500 a $2,500<br/>
Plazo personalizable 3 meses - 6 meses<br/>
Pagos quincenales (Cada 2 semanas)<br/>
Todos los clientes nuevos comienzan con este producto...</p>
<a href="/consumer-loans"><button>Know More</button></a>
                </div>
            </Col>
            <Col md={6} sm={12}>
            <div className="product_card_main">
                    <img src={premier}/>
                    <h3>Préstamo Premier</h3>
                    <p>De 2.500 a 5.000 dólares
                    <br/>
Plazo personalizable 3 meses - 6 meses
<br/>
Pagos quincenales (cada 2 semanas)
<br/>
Disponible exclusivamente para clientes actuales que...
</p>
<a href="/consumer-loans"><button>Know More</button></a>
                </div>
            </Col>
        </Row>
        <Row>
            <Col md={6} sm={12}>
                <div className="product_card_main">
                    <img src={responsive}/>
                    <h3>Prestamo Electronic Express </h3>
                    <p>De $500 a $1,000
                    <br/>
Plazo de 6 meses
<br/>
Pagos quincenales (Cada 2 semanas)
<br/>
SSN / ITIN / Pasaporte, todos califican para aplicar...
</p>
<a href="/electronic-express"><button>Know More</button></a>
                </div>
            </Col>
            <Col md={6} sm={12}>
            <div className="product_card_main">
            <img src={coinstack}/>
                    <h3>Prestamo Comercial</h3>
                    <p>¡Hasta $25,000!
                    <br/>
Plazo de 11 meses
<br/>
Pagos mensuales
<br/>
Exclusivo para LLCs de Tennessee</p>
<a href="/commercial-loan"><button>Know More</button></a>
                </div>
            </Col>
        </Row>
        <Row>
            <Col md={6} sm={12}>
                <div className="product_card_main">
                    <img src={franchise}/>
                    <h3>Prestamo JaniKing</h3>
                    <p>¡Hasta $25,000!
<br/>
Pagos Mensuales
<br/>
Exclusivo para franquicias JaniKing<br/>
                    &nbsp;
</p>
<a href="/en/jani-king"><button>Know More</button></a>
                </div>
            </Col>
        </Row>
      </Container>
      <FooterEn />
    </div>
  );
};

export default ProductEs;
