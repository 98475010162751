// Navbar.jsx

import { useRef, useState } from "react";
import { FaBars, FaTimes, FaSearch } from "react-icons/fa";
import { Container, Row, Col } from 'react-bootstrap';
import { RiMenu4Fill } from "react-icons/ri";
import "../../styles/main.css";
import loans_logo from "../../assets/images/nuestro_loans_logo.png";
import nuestro_icon from "../../assets/icons/nuestro_icon.png";
import en_flag from "../../assets/images/english_flag.png";
import es_flag from "../../assets/images/spanish_flag.png";

function Navbar({ langToggleHref }) {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };


  return (
    <header style={{zIndex:100, position:'relative'}}>
      <Container style={{width:'100%'}}>
        <img src={loans_logo} alt="nuestro loans logo" className="logo_img"/>
        <nav ref={navRef} className="ms-lg-5 ps-lg-5">
          <img src={nuestro_icon} alt="nuestro loans logo" width="80rem" className="mobile_logo" />
          <a href="/en">Home</a>
          <a href="https://nuestro-loans.turnkey-lender.com/Account/Login" target="_blank">Login</a>
          <a href="/en/contactus">Contact Us</a>
          <a href="/en/disclosures">Disclosures</a>
          <a href="/en/financial-literacy">Financial Literacy</a>
          <a href="/en/faqs">FAQs</a>
          <a className="language-toggle" href={langToggleHref}><img src={es_flag} alt="English flag" /></a>
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </nav>
        {/* <div className="search-bar-container ms-4">
          <input type="text" placeholder="Search..." />
          <FaSearch className="search-icon" />
        </div> */}
        <button className="nav-btn" onClick={showNavbar}>
          <RiMenu4Fill />
        </button>
      </Container>
    </header>
  );
}

export default Navbar;
