import React, {useState, useContext} from "react";
import { Routes, Route } from 'react-router-dom';
import HomeEn from "./pages/home/english";
import HomeEs from "./pages/home/spanish";
import ContactEn from "./pages/contactus/english";
import ContactEs from "./pages/contactus/spanish";
import LegalEn from "./pages/disclosures/english";
import LegalEs from "./pages/disclosures/spanish";
import FLEn from "./pages/finanacial_literacy/english";
import FLEs from "./pages/finanacial_literacy/spanish";
import FaqEn from "./pages/faqs/english";
import FaqEs from "./pages/faqs/spanish";
import EEEn from "./pages/electronic_express/english";
import EEEs from "./pages/electronic_express/spanish";
import CLEn from "./pages/commercial_loan/english";
import CLEs from "./pages/commercial_loan/spanish";
import ProductEn from "./pages/products/english";
import ProductEs from "./pages/products/spanish";
import ConsumerLoanEn from "./pages/consumer_loans/english";
import ConsumerLoanEs from "./pages/consumer_loans/spanish";
import Janiking from "./pages/jani_king/english";
import ZelleEn from "./pages/zelle_disclosure/english";
import ZelleEs from "./pages/zelle_disclosure/spanish";

import "./styles/style.css"

// class App extends React.Component {
const App = () => {
  
  // render = () => {
    return (
        <Routes>
          <Route path="/en" element={<HomeEn />} />
          <Route path="/" element={<HomeEs />} />
          <Route path="/en/contactus" element={<ContactEn />} />
          <Route path="/contactus" element={<ContactEs />} />
          <Route path="/en/disclosures" element={<LegalEn />} />
          <Route path="/disclosures" element={<LegalEs />} />
          <Route path="/en/financial-literacy" element={<FLEn />} />
          <Route path="/financial-literacy" element={<FLEs />} />
          <Route path="/en/faqs" element={<FaqEn />} />
          <Route path="/faqs" element={<FaqEs />} />
          <Route path="/en/electronic-express" element={<EEEn />} />
          <Route path="/electronic-express" element={<EEEs />} />
          <Route path="/en/commercial-loan" element={<CLEn />} />
          <Route path="/commercial-loan" element={<CLEs />} />
          <Route path="/en/products" element={<ProductEn />} />
          <Route path="/products" element={<ProductEs />} />
          <Route path="/en/consumer-loans" element={<ConsumerLoanEn />} />
          <Route path="/consumer-loans" element={<ConsumerLoanEs />} />
          <Route path="/en/jani-king" element={<Janiking />} />
          <Route path="/en/zelle-disclosure" element={<ZelleEn />} />
          <Route path="/zelle-disclosure" element={<ZelleEs />} />
        </Routes>
    );
  }
// }

export default App;
