import React from "react";
import "../../styles/style.css";
import "../../styles/main.css";
import NavbarEn from "../../components/navbar/english";
import happyboy from "../../assets/images/landing_page_banner.png";
import products from "../../assets/images/products.png";
import m1 from "../../assets/images/masonary/m12.jpg";
import whynuestro1 from "../../assets/images/why_nuestro_1.0.webp";
import whynuestro2 from "../../assets/images/why_nuestro_1.4.png";
import whynuestro3 from "../../assets/images/why_nuestro_1.5.png";
import whynuestro4 from "../../assets/images/why_nuestro_1.3.png";
import banner_bg from "../../assets/images/banner_bg_copy.png";
import mobile_banner_bg from "../../assets/images/banner_bg.png";
import banner from "../../assets/images/banner.png";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import FooterEn from "../../components/footer/english";
import click from "../../assets/gifs/click.webm";
import SideSocialMediaBar from "../../components/social_media_bar/english";
import logo_ee from "../../assets/images/logo_electronic_express.png";

const HomeEn = () => {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isMacOS = /Macintosh/.test(navigator.userAgent);

  const shouldRenderVideo = !isIOS && !isMacOS;

  return (
    <div>
      <NavbarEn langToggleHref="/"/>
      <SideSocialMediaBar />
      <div style={{ backgroundColor: "rgb(232, 0, 120)" }}>
        <Container fluid>
          <Row>
            <Col sm={6} md={6} xs={6} style={{ zIndex: 50 }}>
              <div style={{ textAlign: "right" }}>
                <img
                  src={happyboy}
                  className="slide-in-left banner-img"
                  style={{ zIndex: 50 }}
                />
                <div className="below_banner_text">
                  <p>
                  Due to an increase in loan applications, we're temporarily pausing new applications to ensure we maintain our commitment to exceptional service. Rest assured, our team is dedicated to processing existing applications efficiently.
                  <br/>
                  For our existing customers, please don't hesitate to call us for assistance. We're here to help! Thank you for your patience and understanding during this time.
                  </p>
                </div>
              </div>
            </Col>
            <Col sm={6} md={6} xs={6}>
              <div className="slide-in-right home_banner_heading">
                <div style={{ textAlign: "left" }}>
                  <img src={banner} className="banner_text_img" />
                  <div class="button-container" style={{textAlign:'right'}}>
                    <a
                      href="https://nuestro-loans.turnkey-lender.com/Account/Login"
                      target="_blank"
                      className="py-lg-3 py-1 banner-apply-button grow-effect"
                    >
                      {shouldRenderVideo && (
  <video className="click_here" loop={true} autoPlay={true} muted playsInline>
    <source src={click} type="video/webm" />
  </video>
)}
                      &nbsp;Login
                    </a>
                  </div>
                  <div></div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <img src={banner_bg} width="100%" className="below_banner_img" />
        <img
          src={mobile_banner_bg}
          width="100%"
          className="mobile_below_banner_img"
        />
      </div>
      <Container fluid className="loan_video">
        <Row>
          <Col md="4" sm="12">
            <h1>How to apply for a Loan?</h1>
          </Col>
          <Col md="2" sm="0">
            {shouldRenderVideo && (
              <video loop={true} autoPlay={true} muted playsInline style={{paddingTop:'80px'}}>
                <source src={click} type="video/webm" />
              </video>
            )}
          </Col>
          <Col md="5" sm="12">
            <div className="faq_video" >
              <iframe src="https://www.youtube.com/embed/nlI8YhxgQI0?si=S01vOV8qfqkYAEwB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </Col>
        </Row>
      </Container>
      <div style={{ textAlign: "center", padding: "40px 0" }}>
        <img src={products} width="47%" className="slide-in-left" />
      </div>
      <Container className="pb-lg-5">
        <Row className="d-flex align-items-stretch">
        <Col md={6} sm={12}>
            {/* Content for the second column */}
            <div
              className="text-center d-flex flex-column justify-content-between h-100"
              style={{ padding: "15px", marginBottom: "15px" }}
            >
              <div className="px-4 pt-lg-5 pt-3 slide-in-left products">
                <h4 style={{ fontFamily: "AvenirBold" }}>
                  Prestamo Migrante
                </h4>
                <br />
                <p>
                  For those who came to the USA with all the desire to do
                  things right! We will accompany you to build a future full of
                  success. With or without ITIN, you don't need a credit
                  history to start building your financial future. New customer
                  opportunities start as low as $500! Apply to find out how
                  much you can start with.
                  <br />
                  <br />
                  <span style={{ fontFamily: "AvenirBold" }}>
                    *Limit of $2,500. All approvals are subject to our credit
                    process.
                  </span>
                </p>
              </div>
            </div>
          </Col>
          <Col  md={6} sm={12}>
            {/* Content for the first column */}
            <div
              className="text-center d-flex flex-column justify-content-between h-100"
              style={{ padding: "15px", marginBottom: "15px" }}
            >
              <div className="px-4 pt-lg-5 pt-3 slide-in-left products">
                <h4 style={{ fontFamily: "AvenirBold" }}>
                  Prestamo Premier
                </h4>
                <br />
                <p>
                  Only for our Premier customers. If you're already a customer
                  of ours, need more than $2,500, and have an impeccable
                  payment history, this product is just for you! Here we
                  reward your commitment to your financial success.
                  <br />
                  <br />
                  <span style={{ fontFamily: "AvenirBold" }}>
                    *Limit of $5,000. Only for Nuestro customers with positive
                    payment history.
                  </span>
                </p>
              </div>
            </div>
          </Col>
          <Col  md={6} sm={12}>
            {/* Content for the first column */}
            <div
              className="text-center d-flex flex-column justify-content-between h-100"
              style={{ padding: "15px", marginBottom: "15px" }}
            >
              <div className="px-4 pt-lg-5 pt-3 slide-in-left products">
                <h4 style={{ fontFamily: "AvenirBold" }}>
                Prestamo para Negocio
                </h4>
                <br />
                <p>
                For our entrepreneurs and business owners, this product is for you! Here we support the growth of your company and are committed to your financial success. If your business is registered as an LLC (Limited Liability Company) you can qualify for up to $25,000.
                  <br />
                  <span style={{color:'rgb(33, 67, 136)', fontWeight:'bold'}}>PROMOCODE: NEGOCIO</span>
                  <br />
                  <span style={{ fontFamily: "AvenirBold" }}>
                  *This credit is available to business owners with an ITIN and an LLC registered in Tennessee.
                  </span>
                </p>
              </div>
            </div>
          </Col>
          <Col  md={6} sm={12}>
            {/* Content for the first column */}
            <div
              className="text-center d-flex flex-column justify-content-between h-100"
              style={{ padding: "15px", marginBottom: "15px" }}
            >
              <div className="px-4 pt-lg-3 pt-3 slide-in-left products">
                <img src={logo_ee} width="150px"/>
                <h4 style={{ fontFamily: "AvenirBold" }}>
                Prestamo Express
                </h4>
                <p>
                For you who want to improve your home, change appliances or want to take advantage of Electronic Express credit offers. Nuestro and Electronic Express make it happen. With or without ITIN, you do not need previous credit history and it helps you start building your financial future. Opportunities for new clients starting at $500! Apply easily and receive a response within 24 hours.
                  <br />
                  <span style={{color:'rgb(33, 67, 136)', fontWeight:'bold'}}>PROMOCODE: EXPRESS</span>
                  <br />
                  <span style={{ fontFamily: "AvenirBold" }}>
                  *Limit of $1,000. All approval is subject to our credit process.
                  </span>
                </p>
              </div>
            </div>
          </Col>
          
        </Row>
      </Container>

      <Container className="fl_home">
        <Row>
          <h1>Free Financial Education Workshops</h1>
          <p>Welcome to Nuestro's Free Financial Education Workshops, empowering communities with essential financial knowledge every Monday, 7:00 p.m. - 8:00 p.m. at 444 Metroplex Dr. Suite 225-B. </p>
          <div style={{textAlign:'center', marginBottom:'10px'}}>
            <img src={m1} style={{width:'320px', borderRadius:'30px'}}/>
          </div>
          <h4>Take control of your financial future with us!</h4>
          <div style={{textAlign:'center', padding:'30px 40px'}}>
            <a href="/financial-literacy">Know More</a>
          </div>
        </Row>
      </Container>
      <div style={{ backgroundColor: "rgb(232, 0, 120)" }}>
        <Container>
          <Row
            className="pe-5 pt-5 pb-5 slide-in-right"
            style={{ textAlign: "right" }}
          >
            <h1
              style={{
                fontFamily: "RockoUltraflf",
                fontSize: "4em",
                color: "#ffffff",
              }}
            >
              Why
              <span
                style={{
                  color: "rgb(33, 67, 136)",
                  fontFamily: "RockoUltraflf",
                }}
              >
                {" "}
                Nuestro
              </span>{" "}
              Loans?
            </h1>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <Col xs={12} md={6} sm={12}>
              <Row>
                <Col xs={6} md={6} sm={6}>
                  <img
                    src={whynuestro1}
                    width="90%"
                    className="pt-1 pb-1 circular-grow"
                  />
                </Col>
                <Col xs={6} md={6} sm={6}>
                  <img
                    src={whynuestro2}
                    width="90%"
                    className="pt-1 pb-1 circular-grow"
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6} sm={12}>
              <Row>
                <Col xs={6} md={6} sm={6}>
                  <img
                    src={whynuestro3}
                    width="90%"
                    className="pt-1 pb-1 circular-grow"
                  />
                </Col>
                <Col xs={6} md={6} sm={6}>
                  <img
                    src={whynuestro4}
                    width="90%"
                    className="pt-1 pb-1 circular-grow"
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="p-5 slide-in-left">
            <h1
              style={{
                fontFamily: "RockoUltraflf",
                fontSize: "4em",
                color: "#ffffff",
              }}
            >
              How does it work?
            </h1>
          </Row>
          <Row className="pe-5 ps-5">
            <Col
              className="p-5 slide-in-right"
              style={{
                backgroundColor: "#ffffff",
                color: "rgb(33, 67, 136)",
                borderRadius: "30px",
              }}
            >
              <p>
                1. Click the Apply Now button to get started! 
              </p>
              <p>
                2. Choose the appropriate credit product, the amount, and the
                repayment term you're looking for. Our products have different
                uses, so make sure you pick the right one. 
              </p>
              <ul>
                <li>Prestamo Migrante: Opportunities for new clients starting at $500! Apply easily to find out how much you can start with</li>
                <li>Prestamo Premier: Only for our existing Premier customers.</li>
                <li>Prestamo Express: For your home needs, in collaboration with Electronic Express.</li>
                <li>Prestamo Negocio: Specially designed for businesses registered as LLCs in Tennessee. Up to $25,000 to capitalize your business.</li>
              </ul>
              <p>
                3. For all loans, you're going to need the following documents:
              </p>
              <ul>
                <li>Photo ID</li>
                <li>Proof of Domicile</li>
                <li>Proof of Employment / Pay</li>
                <li>Three Most Recent Bank Statements</li>
                <li>Valid Bank Account</li>
              </ul>
              <p>
                4. Once approved and signed, you will receive the money via
                Direct Deposit into your connected bank account within
                approximately 48 hours.
              </p>
              <p>
                5. Improve your credit score! By meeting your monthly payments,
                vas a conseguir un mejor credit score. Nuestro Loans reports
                to Clarity, which is a part of Experian, a credit bureau,
                ayudandote a mejorar tu credit profile. En nuestro loans NO
                HAY prepayment fees, so you can pay it off early without extra
                fees.
              </p>
              <p style={{ fontSize: "10px" }}>
                *You must have the necessary documentation to apply for these
                loans.
                <br />
                **If you don’t have a bank account check www.nuestrowallet.com ,
                we may be able to help you.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <FooterEn />
    </div>
  );
};

export default HomeEn;
