import React from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import { Container, Col, Row } from "react-bootstrap";
import Footer from "../../components/footer/english";
import NavBar from "../../components/navbar/english";
import id_card from "../../assets/icons/money-bag.png";
import selfie from "../../assets/icons/exclusive.png";
import home from "../../assets/icons/clock.png";
import "../../styles/style.css";
import SideSocialMediaBar from "../../components/social_media_bar/english";

const CLEn = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflowX:'hidden',
      }}
    >
      <NavBar langToggleHref="/commercial-loan"/>
      <SideSocialMediaBar />
      <div
        className="electronic-express"
        style={{ flex: 1 }}
      >
        {/* <img src={logo} width="30%"/>   */}
        <Container fluid className="commercial_banner">
            <Row>
            <Col className="home_background">
              <div className="white_background">
                <h1>Nuestro Commercial Loans</h1>
                <p>For our entrepreneurs and business owners, this product is for you! Here we support your company's growth and are committed to your financial success. If your business is registered as an LLC (Limited Liability Company) you can qualify for up to $25,000.</p>
                <h3>Promocode: NEGOCIO</h3>
                </div>
                </Col>
            </Row>
            <Row style={{ textAlign: "center" }} className="px-lg-5 py-3 cl_division">
                <Col md={4} sm={12} xs={12}>
                  <img src={id_card} width="170px" />
                  <h3>Up to $25000!</h3>
                </Col>
                <Col md={4} sm={12} xs={12}>
                  <img src={selfie} width="170px" />
                  <h3>Exclusive to LLCs</h3>
                </Col>
                <Col md={4} sm={12} xs={12}>
                  <img src={home} width="170px" />
                  <h3>Minimum 6 months operating</h3>
                </Col>
              </Row>
              <Row>
              <div className="faq_video">
              <iframe src="https://www.youtube.com/embed/95HMHUNJlxQ?si=mffzWHinU4k2SK7l" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
              </Row>
        </Container>
        <Container className="my-5 why_finance">
          <Row>
            <Col md={12} sm={12}>
              <div style={{textAlign:'center'}}>
              <h2 className="mt-2" style={{color:'rgb(232, 0, 120)', fontWeight:'bold', fontFamily:'RockoUltraFLF'}}><span style={{color:'rgb(33, 67, 136)'}}>PROMO CODE:</span> NEGOCIO</h2>
              </div>
              <div>
              <a
                href="https://nuestro-loans.turnkey-lender.com/Lending#/terms"
                className="pt-3 pb-3 ee-apply-button grow-effect"
                target="_blank"
              >
                Apply Now
              </a>
            </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default CLEn;



