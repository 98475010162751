import React from 'react'
import "../../styles/style.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import { Container, Row, Col } from "react-bootstrap";
import Footer from '../../components/footer/spanish';
import NavBar from '../../components/navbar/spanish';
import SideSocialMediaBar from "../../components/social_media_bar/english";
import zelle from "../../assets/documents/Zelle_Disclosure.pdf"

const ZelleEs = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh',  backgroundColor:'rgb(232, 0, 120)' }}>
        <NavBar langToggleHref="/en/zelle-disclosure"/>
        <SideSocialMediaBar />
        <Container className="container container-wrapper zelle px-lg-5" style={{ flex: 1 }}>
            <h1 className="mt-3 mb-3 legal-title text-center grow-effect" style={{fontFamily: 'RockoUltraFLF', color:'rgb(33, 67, 136)', fontSize:'50px'}}>Zelle Divulgaciones</h1>
            <Row>
                <Col className="py-4">
                <ol>
<li>  Si se encuentra en COBROS, puede solicitar DESACTIVAR el pago automático y pasarse a Zelle.
    <ul style={{listStyleType:'none'}}>
        <li>a. Si desea HABILITAR el pago automático, debe presentar una solicitud formal </li>
<li>b. Las solicitudes pueden tardar hasta 24 horas en procesarse. </li>
<li>c. Todos los cargos por retraso se aplicarán en basado a este tiempo de respuesta.</li>
    </ul>
</li>
<br/>
<li> Los pagos Zelle pueden tardar hasta 48 horas en registrarse en nuestro sistema.  </li>
<br/>
<li> USTED, el cliente, es responsable de enviar el pago al número de teléfono <b>CORRECTO</b>. 
<ul style={{listStyleType:'none'}}>
    <li>
        <b>a. 615-753-7870</b>
    </li>
</ul>
</li>
<br/>
<li>  Nuestro puede confirmar pagos de Zelle 48 horas después de que el pago sea recibido a través de nuestro sistema o equipo CS.</li>
<br/>
<li>La cantidad recibida se registrará según el calendario de pagos del cliente. </li>
<br/>
<li> Los pagos Zelle en el mismo día no están garantizados para evitar cargos por demora.</li>
<br/>
<li> Todos los pagos enviados por el cliente después de las 12:00pm CST se considerarán recibidos al día siguiente.Nuestro Financial</li>
<br/>
<li>  Si un pago Zelle se recibe en fin de semana, festivo o día no laborable, el pago se considerará recibido el siguiente día laborable.</li>

                </ol>
                </Col>
            </Row>
            <Row className="text-center mb-5">
                <Col>
                <a href={zelle} className="px-5 py-3">Dscargar</a>
                </Col>
            </Row>
        </Container>
    <Footer/>
    </div>
  )
}

export default ZelleEs;