import React from 'react'
import "../../styles/style.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import { Container, Row, Col } from "react-bootstrap";
import Footer from '../../components/footer/english';
import NavBar from '../../components/navbar/english';
import SideSocialMediaBar from "../../components/social_media_bar/english";
import zelle from "../../assets/documents/Zelle_Disclosure.pdf"

const ZelleEn = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh',  backgroundColor:'rgb(232, 0, 120)' }}>
        <NavBar langToggleHref="/zelle-disclosure"/>
        <SideSocialMediaBar />
        <Container className="container container-wrapper zelle px-lg-5" style={{ flex: 1 }}>
            <h1 className="mt-3 mb-3 legal-title text-center grow-effect" style={{fontFamily: 'RockoUltraFLF', color:'rgb(33, 67, 136)', fontSize:'50px'}}>Zelle Disclosure</h1>
            <Row>
                <Col className="py-4">
                <ol>
<li> If in COLLECTIONS, you may request to DISABLE autopay and switch to Zelle. 
    <ul style={{listStyleType:'none'}}>
        <li>a. If you want to ENABLE autopay, you must submit a formal request </li>
        <li>
b. Requests may take up to 24 hours to process. </li>
<li>c. All late fees will apply based on this turn around time.</li>
    </ul>
</li>
<br/>
<li> Zelle payments may take up to 48 hours to register on our system. </li>
<br/>
<li> YOU, the customer, are responsible for sending the payment to the CORRECT phone number. </li>
<br/>
<li> Nuestro may confirm Zelle payments 48 hours after payment is received via our system or CS team.</li>
<br/>
<li> The amount received will be registered according to customer’s payment calendar. </li>
<br/>
<li> Same day Zelle payments are not guaranteed to avoid late fees. </li>
<br/>
<li> All payments sent by the customer after 12:00pm CST will be considered received the next day.</li>
<br/>
<li> If a Zelle payment is received on a weekend, bank holiday, or non-business day, the payment will be considered received on the next business day. </li>

                </ol>
                </Col>
            </Row>
            <Row className="text-center mb-5">
                <Col>
                <a href={zelle} className="px-5 py-3">Download</a>
                </Col>
            </Row>
        </Container>
    <Footer/>
    </div>
  )
}

export default ZelleEn;