import React from 'react'
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import phone from "../../assets/images/call_icon.png"
import email from "../../assets/images/email_icon.png"
import support from "../../assets/images/support_icon.png"
import address from "../../assets/images/address_icon.png"
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import FooterEn from '../../components/footer/english';
import NavbarEn from '../../components/navbar/english';
import "../../styles/style.css"
import SideSocialMediaBar from "../../components/social_media_bar/english";

const ContactEn = () => {
  return (
    <div style={{overflow:'hidden'}}>
        <NavbarEn langToggleHref="/contactus"/>
        <SideSocialMediaBar />
        <section className="main-slider pt-5" style={{backgroundColor:'rgb(232, 0, 120)'}}>

        <div className="container contact_heading">
          <h1 className='pb-5 slide-in-left'>GET IN TOUCH!</h1>
        </div>
        <div style={{backgroundColor:'#ffffff'}}>
        <Container className="pt-5 pb-5" style={{textAlign:'center'}}>
          <Row>
            <Col sm={3}>
              <img src={phone} width="220px" className="circular-grow"/>
              <div className="mt-4 mb-4 grow-effect">
        <a href="tel:+1 (844) 800-6185" target="_blank" className='pt-2 pb-2 ps-4 pe-4 contact-button'>Call Us</a>
        </div>
            </Col>
            <Col sm={3}>
              <img src={email} width="220px" className="circular-grow"/>
              <div className="mt-4 mb-4 grow-effect">
        <a href="mailto:contact@nuestro.info" target="_blank" className='pt-2 pb-2 ps-4 pe-4 contact-button'>Send Message</a>
        </div>
            </Col>
            <Col sm={3}>
              <img src={support} width="220px" className="circular-grow"/>
              <div className="mt-4 mb-4 grow-effect">
        <a href="https://nuestrofinancialllc.freshdesk.com/support/home" target="_blank" className='pt-2 pb-2 ps-4 pe-4 contact-button'>Get Help</a>
        </div>
            </Col>
            <Col sm={3}>
              <img src={address} width="220px" className="circular-grow"/>
              <div className="mt-4 mb-4 grow-effect">
        <a href="#map" className='pt-2 pb-2 ps-4 pe-4 contact-button'>Visit Us</a>
        </div>
            </Col>
          </Row>
        </Container>
        </div>
        <div id="map">
        <Container fluid style={{backgroundColor:'#ffffff'}}>
        <Row>
            <Col sm="12" md="6">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3224.1509628453696!2d-86.69998732500713!3d36.08980787245819!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88646eff24987ec5%3A0xb8aedb0b08481355!2s444%20Metroplex%20Dr%20b%20225%2C%20Nashville%2C%20TN%2037211%2C%20USA!5e0!3m2!1sen!2sin!4v1703845032843!5m2!1sen!2sin" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='Nuestro Address'></iframe>
            </Col>
            <Col sm="12" md="6">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3248.9026865502183!2d-86.45545942504059!3d35.48195147265213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8863c2f0be92c5f5%3A0x429c7c248723c599!2s405%20Thompson%20St%2C%20Shelbyville%2C%20TN%2037160%2C%20USA!5e0!3m2!1sen!2sin!4v1708583498916!5m2!1sen!2sin" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </Col>
            </Row>
        </Container>
    </div>
    </section>
    <FooterEn/>
    </div>
  )
}

export default ContactEn;