import React from "react";
import "../../styles/style.css";
import "../../styles/main.css";
import NavbarEn from "../../components/navbar/spanish";
import happyboy from "../../assets/images/FL_user.png";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../../components/footer/spanish";
import m1 from "../../assets/images/masonary/m1.jpg";
import m2 from "../../assets/images/masonary/m2.jpg";
import m3 from "../../assets/images/masonary/m3.jpg";
import m4 from "../../assets/images/masonary/m4.jpg";
import m5 from "../../assets/images/masonary/m5.jpg";
import m6 from "../../assets/images/masonary/m6.jpg";
import m7 from "../../assets/images/masonary/m7.jpg";
import m8 from "../../assets/images/masonary/m8.jpg";
import m9 from "../../assets/images/masonary/m9.jpg";
import m10 from "../../assets/images/masonary/m10.jpg";
import m12 from "../../assets/images/masonary/m12.jpg";
import m16 from "../../assets/images/masonary/m16.jpg";
import SideSocialMediaBar from "../../components/social_media_bar/english";


const FLEs = () => {


  return (
    <div>
      <NavbarEn langToggleHref="/en/financial-literacy"/>
      <SideSocialMediaBar />
      <div style={{ backgroundColor: "rgb(232, 0, 120)" }}>
        <Container>
          <Row>
            <Col sm={4} md={4} xs={6} style={{ zIndex: 50 }}>
              <div style={{ textAlign: "right" }}>
                <img
                  src={happyboy}
                  className="slide-in-left fl-banner-img-es"
                  style={{ zIndex: 50 }}
                />
              </div>
            </Col>
            <Col sm={8} md={8} xs={6}>
              <div className="slide-in-right fl_heading">
                <h1>Talleres Gratuitos de Educación Financiera</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="pb-lg-5 pt-lg-5 pt-4">
        <Row className="d-flex align-items-stretch">
          <Col sm={5}>
          </Col>
          <Col sm={7}>
            {/* Content for the second column */}
            <h4 className="fl_description">
            Bienvenidos a los Talleres Gratuitos de Educación Financiera de Nuestro, empoderando a las comunidades con conocimientos financieros esenciales<br/><span style={{color:'rgb(232, 0, 120)', fontFamily:'RockoUltraflf'}}>todos los lunes, de 7:00 p.m. a 8:00 p.m. en<br/><a href="https://maps.app.goo.gl/pWnYTPRGGAXVzJuC6" target="_blank" style={{color:'rgb(232, 0, 120)', fontFamily:'RockoUltraflf'}}>444 Metroplex Dr. Suite 225-B.</a></span><br/> ¡Toma el control de tu futuro financiero con nosotros!</h4>
<h1 className="free_workshop">TALLERES GRATUITOS
</h1>
<div className="text-lg-end pb-3 pt-2 text-center">
<a className="signup_button" href="https://docs.google.com/forms/d/e/1FAIpQLSfHhN7ju5nuvMAqRD2Q_L1soFVzgAO6lYLOCwVuHO66iwRPlg/viewform" target="_blank">Inscríbete Ahora</a>
</div>
          </Col>
        </Row>
      </Container>
      <div style={{ backgroundColor: "rgb(33, 67, 136)" }}>
      <Container fluid>
      <Row>
        <Col sm={3} style={{ padding: 5 }}>
          <img src={m1} width="100%" alt="m1" />
        </Col>
        <Col sm={3} style={{ padding: 5 }}>
          <img src={m2} width="100%" alt="m2" />
        </Col>
        <Col sm={3} style={{ padding: 5 }}>
          <img src={m3} width="100%" alt="m3" />
        </Col>
        <Col sm={3} style={{ padding: 5 }}>
          <img src={m4} width="100%" alt="m4" />
        </Col>
      </Row>
      <Row>
        <Col sm={3} style={{ padding: 5 }}>
          <img src={m5} width="100%" alt="m5" />
        </Col>
        <Col sm={3} style={{ padding: 5 }}>
          <img src={m6} width="100%" alt="m6" />
        </Col>
        <Col sm={3} style={{ padding: 5 }}>
          <img src={m7} width="100%" alt="m7" />
        </Col>
        <Col sm={3} style={{ padding: 5 }}>
          <img src={m8} width="100%" alt="m8" />
        </Col>
      </Row>
      <Row>
        <Col sm={3} style={{ padding: 5 }}>
          <img src={m9} width="100%" alt="m9" />
        </Col>
        <Col sm={3} style={{ padding: 5 }}>
          <img src={m10} width="100%" alt="m10" />
        </Col>
        <Col sm={3} style={{ padding: 5 }}>
          {/* This column spans two rows */}
          <img src={m16} width="100%" alt="m11" />
        </Col>
        <Col sm={3} style={{ padding: 5 }}>
          <img src={m12} width="100%" alt="m12" />
        </Col>
      </Row>
    </Container>
      </div>
      <Footer />
    </div>
  );
};

export default FLEs;
