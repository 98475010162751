import React from "react";
import "../../styles/style.css";
import "../../styles/main.css";
import NavbarEn from "../../components/navbar/english";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import FooterEn from "../../components/footer/english";
import SideSocialMediaBar from "../../components/social_media_bar/english";
import migrant from "../../assets/images/migrant_loans.png";
import premier from "../../assets/images/premier_loans.png";
import responsive from "../../assets/icons/responsive.png";
import franchise from "../../assets/icons/franchise.png";
import coupons from "../../assets/icons/coupons.png";
import coinstack from "../../assets/icons/coin-stack.png";

const ConsumerLoanEs = () => {
  return (
    <div>
      <NavbarEn langToggleHref="/en/consumer-loans"/>
      <SideSocialMediaBar />
      <Container className="consumer_loans">
        <Row>
          <Col>
            <h1>
            Préstamos al Consumidor
            </h1>
          </Col>
        </Row>
        <Row className="my-2">
          <Col md={6} sm={12}>
            <div>
              <img src={migrant} />
            </div>
          </Col>
          <Col md={6} sm={12} style={{ textAlign: "left" }}>
            <div>
              <h3 className="mt-lg-4">Préstamo Migrantes</h3>
              <p>
              De $500 a $2,500
<br/>
3 meses - 6 meses
<br/>
Pagos quincenales (cada 2 semanas)
<br/>
¿Sólo tiene pasaporte? Eres elegible para un préstamo de $500 para empezar."
                <br />
              </p>
            </div>
          </Col>
        </Row>
        {/* <Row className="my-2 px-lg-5 px-3">
        <h4
                style={{
                  color: "rgb(232, 0, 120)",
                  fontFamily: "RockoUltraFLF",
                }}
              >                Fees
              </h4>

            <table style={{textAlign:'left'}}>
              <tr>
                <td>Administration Fee – Account Handling Customary Fee</td>
                <td>1.05% of the remaining capital per Installment</td>
              </tr>
              <tr>
                <td>Administration Fee - Maintenance Fee </td>
                <td>$1.25 per Installment</td>
              </tr>
              <tr>
                <td>Late fee (15 days)</td>
                <td>$15.00</td>
              </tr>
              <tr>
                <td>NSF (Non-Sufficient Funds Fee)</td>
                <td>$15.00</td>
              </tr>
              <tr>
                <td>Origination Fee – Closing Fee</td>
                <td>$25.00</td>
              </tr>
              <tr>
                <td>Origination fee - Processing fee </td>
                <td>10.00% of the loan amount</td>
              </tr>
              <tr>
                <td>Past Due Interest</td>
                <td>12.50% per year of past due principal</td>
              </tr>
            </table>
        </Row> */}

        <Row className="my-5">
          <Col md={6} sm={12}>
            <div>
              <img src={premier} />
            </div>
          </Col>
          <Col md={6} sm={12} style={{ textAlign: "left" }}>
            <div>
              <h3 className="mt-lg-4">Préstamo Premier</h3>
              <p>
              De $2,500 a $5,000
              <br/>
Plazo personalizable 3 meses - 6 meses
<br/>
Pagos quincenales (Cada 2 semanas)
<br/>
Disponible exclusivamente para clientes actuales que han mantenido un historial de pagos puntuales con Nuestro.
                <br />
              </p>
            </div>
          </Col>
        </Row>
        {/* <Row className="my-2 px-lg-5 px-3">
        <h4
                style={{
                  color: "rgb(232, 0, 120)",
                  fontFamily: "RockoUltraFLF",
                }}
              >
                Fees
              </h4>
            <table style={{textAlign:'left'}}>
              <tr>
                <td>Administration Fee – Account Handling Customary Fee</td>
                <td>1.25% of the remaining capital per Installment</td>
              </tr>
              <tr>
                <td>Administration Fee - Maintenance Fee </td>
                <td>$2.50 per Installment</td>
              </tr>
              <tr>
                <td>Late fee (15 days)</td>
                <td>$15.00</td>
              </tr>
              <tr>
                <td>NSF (Non-Sufficient Funds Fee)</td>
                <td>$15.00</td>
              </tr>
              <tr>
                <td>Origination Fee – Closing Fee</td>
                <td>$25.00</td>
              </tr>
              <tr>
                <td>Origination fee - Processing fee </td>
                <td>10.00% of the loan amount</td>
              </tr>
              <tr>
                <td>Past Due Interest</td>
                <td>12.50% per year of past due principal</td>
              </tr>
            </table>
        </Row> */}
      </Container>
      <FooterEn />
    </div>
  );
};

export default ConsumerLoanEs;
