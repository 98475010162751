import React from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import banner from "../../assets/images/chica_ee.png";
import contactus from "../../assets/images/linea_directa.png";
import callnask from "../../assets/images/electronic_express_1.png";
import conosin from "../../assets/images/con_o_sin.png";
import logo_ee from "../../assets/images/logo_electronic_express.png";
import and from "../../assets/images/&_nuestro.png";
import logo_nuestro from "../../assets/images/nuestro_partner.png";
import aprovein from "../../assets/images/aprovein.png";
import time from "../../assets/images/24hrs.png";
import possible from "../../assets/images/electronic_express_2.png";
import { Container, Col, Row } from "react-bootstrap";
import Footer from "../../components/footer/english";
import NavBar from "../../components/navbar/english";
import id_card from "../../assets/icons/id_card.png";
import selfie from "../../assets/icons/selfie.png";
import home from "../../assets/icons/home.png";
import employment from "../../assets/icons/employment.png";
import bank_statement from "../../assets/icons/bank_statement.png";
import "../../styles/style.css";
import SideSocialMediaBar from "../../components/social_media_bar/english";

const EEEn = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflowX:'hidden',
      }}
    >
      <NavBar langToggleHref="/electronic-express"/>
      <SideSocialMediaBar />
      <div
        className="electronic-express gradient-background"
        style={{ flex: 1 }}
      >
        {/* <img src={logo} width="30%"/>   */}
        <Container>
          <Row>
            <Col className="text-center">
            <img src={logo_ee} className="w22"/>
            <img src={and} className="w22"/>
            <img src={possible} className="w22"/>
            <div className="apply_now_bg">
              <h2 className="mt-lg-5 mt-3"><span style={{color:'rgb(33, 67, 136)', fontWeight:'bold'}}>PROMO CODE:</span> EXPRESS</h2>
              </div>
              <div>
              <a
                href="https://nuestro-loans.turnkey-lender.com/Lending#/terms"
                className="pt-3 pb-3 ee-apply-button grow-effect"
                target="_blank"
              >
                Apply Now
              </a>
            </div>
            <img src={conosin} className="wad22"/>
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={6} xs={6}>
              <img src={callnask} className="ee_text"/>
              <a href="tel:(615) 800-6181"><img src={contactus} className="ee_text"/></a>
            </Col>
            <Col md={6} sm={6} xs={6} style={{textAlign:'center'}}>
            <img src={banner} className="ee_banner"/>
            </Col>
          </Row>
          <Row>
            <Col md={12} sm={12}>
            <h1
                  style={{
                    fontFamily: "RockoUltraFLF",
                    color: "rgb(33, 67, 136)",
                  }}

                  className="text-center pt-3"
                >
                  Documents Needed
                </h1>
            <Row style={{ textAlign: "center" }}>
              <Col md={1} sm={0} xs={0}></Col>
                <Col md={2} sm={6} xs={6}>
                  <img src={id_card} width="170px" />
                </Col>
                <Col md={2} sm={6} xs={6}>
                  <img src={selfie} width="170px" />
                </Col>
                <Col md={2} sm={6} xs={6}>
                  <img src={home} width="170px" />
                </Col>
                <Col md={2} sm={6} xs={6}>
                  <img src={employment} width="170px" />
                </Col>
                <Col md={2} sm={12} xs={12}>
                  <img src={bank_statement} width="170px" />
                </Col>
                <Col md={1} sm={0} xs={0}></Col>
              </Row>
            </Col>
        </Row>
        </Container>
        <Container className="my-5 why_finance">
          <Row>
            <Col md={12} sm={12}>
            <img src={aprovein} width="45%" />
                  <img src={time} width="30%" />
                <li style={{listStyle:'none'}}>
                  <h5>What are the Rates?</h5>
                  <p>We handle a competitive annual interest rate, Apply Today!</p>
                </li>
                <li style={{listStyle:'none'}}>
                  <h5>What are the repayment terms?</h5>
                  <p>
                    All Electronic Express x Nuestro Financial loans are for 6
                    months and will have a biweekly payment.
                  </p>
                </li>
                <li style={{listStyle:'none'}}>
                  <h5>Up to $1,000 financing!</h5>
                  <p>All applications subject to approval</p>
                </li>
              <div style={{textAlign:'center'}}>
              <h2 className="mt-2" style={{color:'rgb(232, 0, 120)', fontWeight:'bold', fontFamily:'RockoUltraFLF'}}><span style={{color:'rgb(33, 67, 136)'}}>PROMO CODE:</span> EXPRESS</h2>
              </div>
              <div>
              <a
                href="https://nuestro-loans.turnkey-lender.com/Lending#/terms"
                className="pt-3 pb-3 ee-apply-button grow-effect"
                target="_blank"
              >
                Apply Now
              </a>
            </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default EEEn;



