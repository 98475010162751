import React from "react";
import "../../styles/style.css";
import "../../styles/main.css";
import NavbarEn from "../../components/navbar/english";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import FooterEn from "../../components/footer/english";
import SideSocialMediaBar from "../../components/social_media_bar/english";
import migrant from "../../assets/icons/migrant.png"
import premier from "../../assets/icons/premier.png"
import responsive from "../../assets/icons/responsive.png"
import franchise from "../../assets/icons/franchise.png"
import coupons from "../../assets/icons/coupons.png"
import coinstack from "../../assets/icons/coin-stack.png"

const ProductEn = () => {

  return (
    <div>
      <NavbarEn langToggleHref="/products"/>
      <SideSocialMediaBar />
      <Container className="Product_heading">
        <Row>
            <Col>
            <h1>Loan Products</h1>
            </Col>
        </Row>
        <Row>
            <Col md={6} sm={12}>
                <div className="product_card_main">
                    <img src={migrant}/>
                    <h3>Migrant Loan</h3>
                    <p>$500 to $2,500 
<br/>
Customizable Term 3 months – 6 months
<br/>
Biweekly payments (Every 2 weeks)
<br/>
All new clients start with this product...</p>
<a href="/en/consumer-loans"><button>Know More</button></a>
                </div>
            </Col>
            <Col md={6} sm={12}>
            <div className="product_card_main">
                    <img src={premier}/>
                    <h3>Premier Loan</h3>
                    <p>$2,500 to $5,000
<br/>
Customizable Term 3 months – 6 months
<br/>
Biweekly payments (Every 2 weeks)
<br/>
Available exclusively for current clients who've...</p>
<a href="/en/consumer-loans"><button>Know More</button></a>
                </div>
            </Col>
        </Row>
        <Row>
            <Col md={6} sm={12}>
                <div className="product_card_main">
                    <img src={responsive}/>
                    <h3>Electronic Express </h3>
                    <p>$500 to $1,000
<br/>
6-month Term 
<br/>
BiWeekly payments (Every 2 weeks)
<br/>
SSN / ITIN / Passport, all qualify to apply.....
</p>
<a href="/en/electronic-express"><button>Know More</button></a>
                </div>
            </Col>
            <Col md={6} sm={12}>
            <div className="product_card_main">
            <img src={coinstack}/>
                    <h3>Commercial Loan</h3>
                    <p>Up to $25,000!
                    <br/>
11 Month Term
<br/>
Monthly Payments
<br/>
Exclusive to Tennessee LLCs...</p>
<a href="/en/commercial-loan"><button>Know More</button></a>
                </div>
            </Col>
        </Row>
        <Row>
            <Col md={6} sm={12}>
                <div className="product_card_main">
                    <img src={franchise}/>
                    <h3>JaniKing Loan</h3>
                    <p>Up to $25,000!
<br/>
Monthly Payments
<br/>
Exclusive to JaniKing franchisees<br/>
                    &nbsp;
</p>
<a href="/en/jani-king"><button>Know More</button></a>
                </div>
            </Col>
        </Row>
      </Container>
      <FooterEn />
    </div>
  );
};

export default ProductEn;
