import React from 'react';
import "../../styles/main.css";
import mail from "../../assets/icons/mail.png";
import whatsapp from "../../assets/icons/whatsapp.png";
import call from "../../assets/icons/call.png";

const SideSocialMediaBar = () => {
    const phoneNumber = encodeURIComponent('+16153624628');
  return (
    <div className="side-social-media-bar">
    {/* Social media icons */}
    <a href="tel:615-800-6181" className="social-media-icon" style={{backgroundColor:'#62c956'}}>
      <img src={call} alt="phone-call" /><br/><span style={{ color: 'white', textDecoration:'none' }}>Phone</span>
    </a>
    <a href="mailto:contact@nuestro.info" className="social-media-icon" style={{backgroundColor:'#bf1866'}}>
      <img src={mail} alt="mail" /><br/><span style={{ color: 'white', textDecoration:'none' }}>Email</span>
    </a>
    <a href={`whatsapp://send?phone=${phoneNumber}`} className="social-media-icon" style={{backgroundColor:'#2e41a8'}}>
      <img src={whatsapp} alt="chat" /><br/><span style={{ color: 'white', textDecoration:'none' }}>Chat</span>
    </a>
    {/* Add more social media icons as needed */}
</div>

  );
};

export default SideSocialMediaBar;
