import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import NavBar from "../../components/navbar/english";
import Footer from "../../components/footer/english";
import SideSocialMediaBar from "../../components/social_media_bar/english";
import "../../styles/janiKing.css"; // Import the CSS file

const JaniKing = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", overflowX: 'hidden' }}>
      <NavBar />
      <SideSocialMediaBar />
      <div className="nuestro-services gradient-background-jk" style={{ flex: 1 }}>
        <Container fluid className="commercial_banner">
          <Row>
            <Col className="home_background">
              <div className="white_background">
                <h1>Welcome to Nuestro!</h1>
                <p>We're excited to introduce you to our partnership with Jani King franchisees, a collaboration that brings unparalleled excellence in cleaning and maintenance services to our clients.</p>
                <p>Nuestro's partnership with Jani King franchisees enriches our service offerings by leveraging their local expertise and resources. This collaboration allows us to deliver personalized solutions tailored to the specific needs of each client, ensuring exceptional results every time.</p>
              </div>
            </Col>
          </Row>
          <Row style={{ textAlign: "center" }} className="px-lg-5 py-3 nuesto_division">
            <Col md={12} sm={12}>
              <div style={{textAlign:'center'}}>
                <h2 className="mt-2" style={{color: "white", fontWeight:'bold', fontFamily:'RockoUltraFLF'}}>Why Choose Nuestro with Jani King Franchisees?</h2>
              </div>
            </Col>
          </Row>
          <Row style={{ textAlign: "center" }} className="px-lg-5 py-3 nuesto_division">
            <Col md={4} sm={12} xs={12}>
              <h3>Exceptional Quality</h3>
              <p>Our partnership with Jani King franchisees ensures that every service is delivered to the highest standards of quality and professionalism.</p>
            </Col>
            <Col md={4} sm={12} xs={12}>
              <h3>Customized Solutions</h3>
              <p>We tailor our services to meet the specific needs of each client, ensuring maximum satisfaction and efficiency.</p>
            </Col>
            <Col md={4} sm={12} xs={12}>
              <h3>Local Expertise</h3>
              <p>Jani King franchisees bring local knowledge and resources to the table, enhancing our ability to deliver effective solutions tailored to local requirements.</p>
            </Col>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <Col>
              <h3>Reliability</h3>
              <p>With Nuestro and Jani King franchisees, you can trust that your facility will receive consistent, reliable service, ensuring a clean and healthy environment for all.</p>
            </Col>
          </Row>
        </Container>
        <Container className="my-5 why_nuestro">
          <Row>
            <Col md={12} sm={12}>
              <a href="https://example.com/apply" className="pt-3 pb-3 nuestro-apply-button grow-effect" target="_blank" rel="noopener noreferrer">
                Apply Now
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default JaniKing;


